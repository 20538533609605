import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { DateRangePicker } from 'react-dates'
import loadGif from '@res/images/loading.gif'

import Moment from 'moment-timezone'
import { Checkbox } from '@components/common/form'

import { Calendar } from '@containers/proposal'
import { AccountExecutiveSection } from '@containers/order/edit/sections'
import { AuthorizedDisplay } from '@containers/common/auth'
import { SendBatchProposalPDFsEmailModal } from '@components/proposal/proposalSections/panel'
import trash from '@res/images/bin.svg'
import list from '@res/images/icon-list.png'
import calendarIcon from '@res/images/icon-calendar-white.png'
import { OrderableConfirmationModal } from '@components/common/modal'

const SEND_BY_EMAIL = 'Send By Email'
const EMAIL_COMBINED_PDFS = 'Email Combined PDFs'
const BULK_APPROVE = 'Bulk Approve'
// const COMBINE_PDF = 'Combine PDF'
const DELETE = 'Delete'

export class ProposalDashboard extends Component {
  state = {
    proposals: [],
    proposalCards: [],
    selectedProposals: {},
    dinerProfiles: {},

    accountExecutiveId: undefined,
    displayViewMore: true,
    filter: {
      descendingAccountName: true,
      descendingClientSetUpTime: true,
      descendingCreatedAt: true,
      descendingTotal: true,
      descendingHeadCount: true,
      descendingVendor: true,
    },
    noProposalsToLoad: false,
    option: '',
    page: 1,
    proposalDate: Moment(),
    results_per_page: 20,
    selectAll: false,
    search: '',
    selectedMonth: null,
    showCalendar: false,
    showSendEmailModal: false,
    showBulkApprovalModal: false,
    startDate: Moment().startOf('month'),
    endDate: Moment().endOf('month'),
    calendarStartDate: Moment().startOf('month'),
  }

  componentWillMount() {
    const { proposalDashboardParams } = this.props
    const {
      search,
      status,
      fromDate: startDate,
      toDate: endDate,
    } = proposalDashboardParams
    const params = { search, status, startDate, endDate }

    Object.keys(params).forEach((key) =>
      params[key] === undefined ? delete params[key] : {},
    )

    if (Object.keys(params).length > 0) {
      this.setState({ ...params })
    }
  }

  componentDidMount() {
    ;(async () => {
      const { loadLandingProposals } = this.props
      await loadLandingProposals(Moment())
      await this.loadProposals()
      this.setState({ noProposalsToLoad: false })
    })()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScroll)
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.proposals !== this.props.proposals ||
      nextProps.proposalDashboard !== this.props.proposalDashboard
    ) {
      const { proposals, proposalDashboard } = nextProps
      this.setState(
        {
          proposals: proposals.sort(
            (a, b) => Moment(b.createdAt) - Moment(a.createdAt),
          ),
          proposalCards: proposalDashboard.sort(
            (a, b) => Moment(b.createdAt) - Moment(a.createdAt),
          ),
        },
        () => this.loadDinerProfiles(),
      )
    }
  }

  checkScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      // this.setState({ page: this.state.page + 1 }, async () => { this.getMoreProposals() })
      this.getMoreProposals()
    }
  }

  deleteProposals = (proposalIdsToDelete) => async () => {
    let { proposals, proposalCards } = this.state
    const { selectedProposals } = this.state
    const { deleteProposals } = this.props
    const proposalsToDelete = proposals.filter((p) =>
      proposalIdsToDelete.includes(p.id),
    )
    const deletedProposalIds = (await deleteProposals(proposalsToDelete)) || []
    proposals = proposals.filter((p) => deletedProposalIds.indexOf(p.id) === -1)
    proposalCards = proposalCards.filter(
      (p) => deletedProposalIds.indexOf(p.id) === -1,
    )
    deletedProposalIds.forEach((p) => {
      if (Object.prototype.hasOwnProperty.call(selectedProposals, p)) {
        delete selectedProposals[p]
      }
    })
    this.setState({ proposals, proposalCards, selectedProposals }, () =>
      this.loadProposals(),
    )
  }

  generateSmallCalendars = () => {
    const { isLoading, orders } = this.props
    const { calendarStartDate, proposals } = this.state
    const calendars = []

    for (let i = 0; i < 3; i++) {
      const date = calendarStartDate.clone().add(i, 'months')
      calendars.push(
        <Calendar
          isLoading={isLoading}
          isSmallCalendar={true}
          selectMonth={(month) => this.setState({ selectedMonth: month })}
          month={date.month()}
          year={date.year()}
          proposals={proposals}
          orders={orders}
        />,
      )
    }

    return calendars
  }

  getMoreProposals = async () => {
    const { page } = this.state
    this.setState({ page: page + 1 }, () => this.loadProposals())
    // const params = { page: page + 1, search, results_per_page, status: 'created', fromDate: startDate, toDate: endDate }
    // const additionalProposals = await loadProposals( params )
    // if ( additionalProposals.length === 0 ) {
    //   this.setState({ noProposalsToLoad: true })
    // }
    // else {
    //   const finalArray = proposals.concat( additionalProposals )
    //   this.setState({ page: page + 1, noProposalsToLoad: false })
    // }
  }

  loadInitialProposalRange = async () => {
    const { calendarStartDate: stateStartDate } = this.state
    const { loadProposals } = this.props
    const startDate = stateStartDate.clone()
    const endDate = startDate.clone().add(2, 'months').endOf('month')

    const { search, accountExecutiveId } = this.state
    const params = {
      search,
      status: 'created',
      results_per_page: 500,
      fromDate: startDate,
      toDate: endDate,
    }

    if (accountExecutiveId) {
      params.accountExecutiveId = accountExecutiveId
    }

    await loadProposals(params)

    this.setState({ noProposalsToLoad: false })
  }

  loadProposals = async () => {
    const {
      loadProposals,
      loadLandingProposals,
      setLastLoadProposalDashboardParams,
    } = this.props
    const {
      page,
      search,
      results_per_page,
      startDate,
      endDate,
      accountExecutiveId,
    } = this.state
    const params = {
      page,
      search,
      results_per_page,
      status: 'created',
      fromDate: startDate,
      toDate: endDate,
    }

    if (accountExecutiveId) {
      params.accountExecutiveId = accountExecutiveId
    }

    await loadLandingProposals(Moment())
    await loadProposals(params)
    this.setState({ noProposalsToLoad: false })
    setLastLoadProposalDashboardParams(params)
  }

  loadProposalsRange = async (prev = true) => {
    const { calendarStartDate: stateStartDate } = this.state
    const { loadProposals } = this.props
    const change = prev ? -3 : 3
    const startDate = stateStartDate.add(change, 'months')
    const endDate = startDate.clone().add(2, 'months').endOf('month')

    const { search, accountExecutiveId } = this.state
    const params = {
      search,
      status: 'created',
      results_per_page: 500,
      fromDate: startDate,
      toDate: endDate,
    }

    if (accountExecutiveId) {
      params.accountExecutiveId = accountExecutiveId
    }

    await loadProposals(params)

    this.setState({ noProposalsToLoad: false })
  }

  loadDinerProfiles = async () => {
    const { proposals } = this.state
    const { loadDinerProfiles } = this.props

    const dinerProfileIds = proposals
      .filter((p) => p.dinerProfileId)
      .map((p) => p.dinerProfileId)
      .filter((id, index, self) => self.indexOf(id) === index)
    const dinerProfiles = await loadDinerProfiles({ ids: dinerProfileIds })

    this.setState({
      dinerProfiles: dinerProfiles.reduce((acc, dp) => {
        acc[dp.id] = dp

        return acc
      }, {}),
    })
  }

  sortProposals = (index) => () => {
    const sortableAttribs = [
      'accountName',
      'clientSetUpTime',
      'createdAt',
      'total',
      'headCount',
      'vendor',
    ]
    if (sortableAttribs.indexOf(index) === -1) {
      return
    }

    const { proposals, filter } = this.state

    if (index === 'accountName') {
      proposals.sort((a, b) => {
        const itemA = a.accountName && a.accountName.toUpperCase()
        const itemB = b.accountName && b.accountName.toUpperCase()

        if (filter.descendingAccountName === true) {
          if (itemA > itemB) {
            return -1
          }
          if (itemA < itemB) {
            return 1
          }
        } else if (filter.descendingAccountName === false) {
          if (itemA < itemB) {
            return -1
          }
          if (itemA > itemB) {
            return 1
          }
        }

        return 0
      })

      filter.descendingAccountName = !filter.descendingAccountName
    } else if (index === 'clientSetUpTime') {
      proposals.sort((a, b) => {
        const timeA = a.clientSetUpTime
        const timeB = b.clientSetUpTime
        if (timeA === undefined || timeB === undefined) {
          return 0
        }

        if (filter.descendingClientSetUpTime === true) {
          return Moment(timeB) - Moment(timeA)
        } else if (filter.descendingClientSetUpTime === false) {
          return Moment(timeA) - Moment(timeB)
        }
      })

      filter.descendingClientSetUpTime = !filter.descendingClientSetUpTime
    } else if (index === 'createdAt') {
      proposals.sort((a, b) => {
        if (filter.descendingCreatedAt === true) {
          return Moment(b.createdAt) - Moment(a.createdAt)
        } else if (filter.descendingCreatedAt === false) {
          return Moment(a.createdAt) - Moment(b.createdAt)
        }
      })

      filter.descendingCreatedAt = !filter.descendingCreatedAt
    } else if (index === 'total') {
      proposals.sort((a, b) => {
        if (filter.descendingTotal === true) {
          return b.total - a.total
        } else if (filter.descendingTotal === false) {
          return a.total - b.total
        }
      })

      filter.descendingTotal = !filter.descendingTotal
    } else if (index === 'headCount') {
      proposals.sort((a, b) => {
        if (filter.descendingHeadCount === true) {
          return b.headCount - a.headCount
        } else if (filter.descendingHeadCount === false) {
          return a.headCount - b.headCount
        }
      })

      filter.descendingHeadCount = !filter.descendingHeadCount
    } else if (index === 'vendor') {
      proposals.sort((a, b) => {
        const { chefName: aChef, concept: aConcept } = a
        const { chefName: bChef, concept: bConcept } = b

        let result = 0
        if (aChef > bChef) {
          result = 1
        } else if (aChef < bChef) {
          result = -1
        } else {
          if (aConcept > bConcept) {
            result = 1
          } else if (aConcept < bConcept) {
            result = -1
          }
        }

        return filter.descendingVendor ? -result : result
      })

      filter.descendingVendor = !filter.descendingVendor
    }

    this.setState({ proposals, filter })
  }

  onChangeCheckbox = (e) => {
    const { checked, value: key } = e.target
    if (key === 'selectAll') {
      const { proposals, selectedProposals } = this.state
      proposals.forEach((p) => {
        selectedProposals[p.id] = checked
      })
      this.setState({ selectedProposals, selectAll: checked })
    } else {
      const { selectedProposals } = this.state
      selectedProposals[key] = checked

      if (checked === false) {
        this.setState({ selectedProposals, selectAll: checked })
      }
      this.setState({ selectedProposals })
    }
  }

  onClearDates = (e) => {
    e.preventDefault()

    this.setState({ page: 1, startDate: null, endDate: null }, () =>
      this.loadProposals(),
    )
  }

  onClickAction = async (e) => {
    e.preventDefault()

    const { option, proposals } = this.state
    const { emailProposal, loadLandingProposals, displayFailureMessage } =
      this.props

    let { selectedProposals } = this.state
    selectedProposals = Object.keys(selectedProposals).filter(
      (k) => selectedProposals[k] !== false,
    )

    if (option === SEND_BY_EMAIL) {
      ;(async () => {
        await emailProposal(selectedProposals)
        loadLandingProposals(Moment())
      })()
    }
    if (option === BULK_APPROVE) {
      const clientIds = {}
      const errors = []
      const proposalDetailsById = {}
      selectedProposals.forEach((id) => {
        const proposal = proposals.find((p) => p.id === id)
        if (!proposal) {
          errors.push('Proposal not found')

          return
        } else if (!proposal.accountId) {
          errors.push('Proposal does not have a client')

          return
        }
        proposalDetailsById[id] = {
          orderNumber: proposal.orderNumber,
          date: proposal.dateMoment,
        }

        clientIds[proposal.accountId] = true
      })
      if (Object.keys(clientIds).length !== 1) {
        displayFailureMessage(
          'Can only bulk approve proposals for one client at a time',
        )

        return
      }
      const { confirmationModal } = this.props
      const dates = Object.values(proposalDetailsById)
        .sort((a, b) => (a.date.isBefore(b.date) ? -1 : 1))
        .map((p) => `${p.date.format('MM/DD/YYYY')} - ${p.orderNumber}`)
        .join('<br />')
      const confirmed = await confirmationModal.show({
        text: `<h1><strong>Are you sure you want to bulk approve these proposals for the following dates: </strong></h1> <br /> ${dates}`,
      })
      if (confirmed) {
        this.setState({ showBulkApprovalModal: true })
      }
    }
    if (option === EMAIL_COMBINED_PDFS) {
      this.setState({ showSendEmailModal: true })
    } else if (option === DELETE) {
      this.deleteProposals(selectedProposals)()
    }
  }

  onSendBatchProposalPDFEmail = async (recipients) => {
    const { emailProposal, loadLandingProposals } = this.props
    let { selectedProposals } = this.state
    selectedProposals = Object.keys(selectedProposals).filter(
      (k) => selectedProposals[k] !== false,
    )
    this.setState({ showSendEmailModal: false })
    await emailProposal(selectedProposals, recipients)
    loadLandingProposals(Moment())
  }

  onDatesChange = (startDate, endDate) => {
    this.setState({ page: 1, startDate, endDate }, () => this.loadProposals())
  }

  onNewProposal = (e) => {
    e.preventDefault()

    const { newProposal } = this.props
    newProposal && newProposal()
  }

  onSearch = (e) => {
    window.removeEventListener('scroll', this.checkScroll)
    const search = e.target.value

    this.props.clearProposals()

    this.setState({ page: 1, search }, () => {
      const { showCalendar } = this.state
      clearTimeout(this.searchTimer)
      this.searchTimer = showCalendar
        ? setTimeout(this.loadInitialProposalRange, 300)
        : setTimeout(this.loadProposals, 300)
    })
  }

  onSelectProposal = (proposalId) => () => {
    // this.setState({ activeOrderableId: proposalId })
    this.props.selectProposal(proposalId)
  }

  onSelectSalesRep = ({ accountExecutive }) => {
    if (accountExecutive) {
      this.setState(
        { accountExecutiveId: accountExecutive.id, page: 1 },
        () => {
          const { showCalendar } = this.state
          showCalendar ? this.loadInitialProposalRange() : this.loadProposals()
        },
      )
    }
  }

  onViewMoreProposals = (e) => {
    e.preventDefault()
    window.addEventListener('scroll', this.checkScroll)

    this.setState({ displayViewMore: false }, this.getMoreProposals)
  }

  renderBackButton = () => {
    const { selectedMonth, minOrderableDate, calendarStartDate } = this.state

    if (selectedMonth !== null) {
      return (
        <p
          className="text-base cursor-pointer"
          onClick={() => this.setState({ selectedMonth: null })}
        >
          ◄ Back to Three Months View
        </p>
      )
    } else if (
      minOrderableDate &&
      minOrderableDate.isAfter(calendarStartDate)
    ) {
      return <p className="text-base cursor-pointer">At Oldest Menu</p>
    } else {
      return (
        <p
          className="text-base cursor-pointer"
          onClick={() =>
            this.setState({ selectedMonth: null }, () =>
              this.loadProposalsRange(),
            )
          }
        >
          ◄ See Previous Three Months
        </p>
      )
    }
  }

  renderForwardButton = () => {
    const { selectedMonth } = this.state

    if (selectedMonth !== null) {
      return null
    } else {
      return (
        <p
          className="text-base cursor-pointer"
          onClick={() =>
            this.setState({ selectedMonth: null }, () =>
              this.loadProposalsRange(false),
            )
          }
        >
          See Next Three Months ►
        </p>
      )
    }
  }

  renderProposal(proposal) {
    const { selectedProposals } = this.state
    const {
      id,
      accountName,
      chefs,
      chefsString,
      concept,
      headCount,
      orderNumber,
      total,
      clientSetUpTime,
      status,
      order,
      createdAt,
    } = proposal
    const selected = selectedProposals[id] ? true : false
    const isApproved = status === 'final'

    return (
      <tr className="cursor-pointer border-b border-solid border-gray-500">
        <td className="pb-2 align-middle w-4">
          <Checkbox
            testId="proposal-dash-checkbox"
            value={proposal.id}
            checked={selected}
            onChange={this.onChangeCheckbox}
            inputClass="proposal-checkbox"
          />
        </td>
        <td
          className="regular nowrap text-hungryGray text-base"
          onClick={this.onSelectProposal(proposal.id)}
        >
          <NavLink exact to="/proposals">
            <p className="regular text-indigo-700 text-lg pt-2">
              {accountName}
            </p>
            <p className="regular text-indigo-700 text-sm pb-2">
              {orderNumber}
            </p>
            <p
              className="regular text-indigo-700 text-sm pb-2"
              data-testId="order-number"
            >
              {order || ''}
            </p>
          </NavLink>
        </td>
        <td className="regular nowrap text-hungryGray text-base text-center align-middle">
          {Moment(clientSetUpTime).format('dddd')}
          <br />
          {Moment(clientSetUpTime).format('MM/DD/YYYY')}
        </td>
        <td className="regular nowrap text-hungryGray text-base text-center align-middle">
          {Moment(createdAt).format('dddd')}
          <br />
          {Moment(createdAt).format('MM/DD/YYYY')}
        </td>
        <td className="regular nowrap text-hungryGray text-base text-center align-middle">
          {Moment(clientSetUpTime).format('h:mm A')}
        </td>
        <td className="regular nowrap text-hungryGray text-base text-center align-middle">
          {headCount}
        </td>
        <td className="regular nowrap text-hungryGray text-base text-center align-middle">
          {chefs.length === 0 ? (
            <p style={{ color: '#E05267' }} className="font-semibold">
              No Vendor Selected
            </p>
          ) : (
            <>
              <p className="regular pt-2">{concept} by</p>
              <p className="regular pb-2">{chefsString}</p>
            </>
          )}
        </td>
        <td
          className="regular nowrap text-hungryGray text-base text-center align-middle"
          onClick={this.onSelectProposal(id)}
        >
          <NavLink
            exact
            to={`/proposals/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="regular text-indigo-700 text-lg">
              {chefs.length === 0 ? 'Add Vendor' : 'Edit Proposal'}
            </p>
          </NavLink>
        </td>
        <td className="text-right regular nowrap text-hungryGray text-base">
          <p className="text-lg bold">{total}</p>
          <p
            className={
              isApproved
                ? 'text-green-600 uppercase bold text-sm'
                : 'text-yellow-600 bold text-sm'
            }
          >
            {isApproved ? 'Approved' : 'Not Approved'}
          </p>
        </td>
        <td className="cursor-pointer align-middle">
          <img
            className="w-5 ml-3"
            src={trash}
            onClick={this.deleteProposals([proposal.id])}
          />
        </td>
      </tr>
    )
  }

  renderProposalCards(proposal) {
    const { dinerProfiles } = this.state

    const dinerProfile = dinerProfiles[proposal.dinerProfileId]

    return (
      <div
        key={proposal.id}
        className="w-1/4 shadow rounded mr-4 border border-gray-400 border-solid cursor-pointer hover:shadow-lg max-w-xs"
        onClick={this.onSelectProposal(proposal.id)}
      >
        <NavLink
          exact
          to="/proposals"
          className="no-underline hover:no-underline"
        >
          <div className="p-4">
            <div className="flex flex-row justify-between">
              <p className="regular text-gray-600 text-base mb-3">
                {proposal.name}
              </p>
              <p className="regular text-gray-600 text-base mb-3">
                Created: {Moment(proposal.createdAt).format('MM/DD/YYYY')}
              </p>
            </div>
            <p className="bold text-gray-700 text-lg mb-1">
              {proposal.account}{' '}
            </p>
            <p className="regular text-base mb-1">
              <span className="font-semibold">Event Date:</span>{' '}
              {Moment(proposal.clientSetUpTime).format('dddd, MM/DD/YYYY')}{' '}
            </p>
            <p className="regular text-base mb-1">
              <span className="font-semibold">Event Time:</span>{' '}
              {Moment(proposal.clientSetUpTime).format('h:mm a')}{' '}
            </p>
            <p className="regular text-base mb-1">
              <span className="font-semibold">Headcount:</span>{' '}
              {proposal.headCount}{' '}
            </p>
            <p className="regular text-base mb-1">
              <span className="font-semibold">Profile:</span>{' '}
              {(dinerProfile && dinerProfile.name) || 'N/A'}{' '}
            </p>
            <p className="regular text-base mb-1">
              <span className="font-semibold">Vendor:</span>{' '}
              {proposal.concept || 'Custom Concept'} by {proposal.chef}{' '}
            </p>
            <p
              style={{ color: '#E05267' }}
              className="regular text-lg mb-1 mt-2 whitespace-pre"
            >
              {proposal.order ? proposal.order : ' '}{' '}
            </p>
            <div className="border-t border-solid border-gray-400 mt-6 pt-2 text-2xl text-right regular text-hungryGray">
              ${Number(proposal.total).toLocaleString(2)}
            </div>
          </div>
          {proposal.status === 'final' ? (
            <div className="text-center bold bg-green-300 text-green-600 uppercase text-xl p-3">
              Approved
            </div>
          ) : (
            <div className="text-center bold bg-yellow-200 text-yellow-600 uppercase text-xl p-3">
              Not Approved
            </div>
          )}
        </NavLink>
      </div>
    )
  }

  renderNotPDFButtons = (option) => {
    return this.props.loadingEmail ? (
      <button
        className="whitespace-no-wrap ml-2 text-center bold bg-green-600 text-white xl:text-lg text-sm px-3 rounded py-2"
        onClick={this.onClickAction}
      >
        {option || 'No Action'}
      </button>
    ) : (
      <img className="ml-2 h-12" src={loadGif} />
    )
  }

  render() {
    const {
      displayViewMore,
      noProposalsToLoad,
      option,
      search,
      selectAll,
      selectedProposals,
      proposalCards,
      proposals,
      selectedMonth,
      showCalendar,
      showSendEmailModal,
      startDate,
      showBulkApprovalModal,
    } = this.state
    const {
      isLoading,
      orders,
      baseUri,
      loadLandingProposals,
      proposalsToOrders,
    } = this.props
    const options = [SEND_BY_EMAIL, EMAIL_COMBINED_PDFS, BULK_APPROVE, DELETE]
    let selectedProposalsCount = 0
    const selectedIds = []
    Object.keys(selectedProposals).forEach((k) => {
      if (selectedProposals[k] === true) {
        selectedProposalsCount += 1
        selectedIds.push(k)
      }
    })

    const nProposals = proposals.length
    let lowerIndex = 0
    let upperIndex = 0
    if (nProposals >= 1) {
      lowerIndex = 1
      upperIndex = nProposals > 20 ? 20 : nProposals
    }
    const indexString = `${lowerIndex}-${upperIndex} of ${nProposals}`

    return (
      <div className="w-full">
        <div className="flex justify-between">
          <h2 className="extra-bold hungry-grey text-4xl text-hungryGray text-left">
            Your Recent Proposals
          </h2>
          <a
            target="_blank"
            key="newChef"
            href="#"
            onClick={this.onNewProposal}
          >
            <button className="text-center bold bg-green-600 text-white text-xl p-3 rounded px-5">
              New Proposal
            </button>
          </a>
        </div>
        <div>
          <AuthorizedDisplay
            roles={['master admin', 'sales lead', 'sales rep', 'chef lead']}
          >
            <AccountExecutiveSection onChange={this.onSelectSalesRep} />
          </AuthorizedDisplay>
        </div>
        <p className="text-hungryGray text-2xl mt-6 mb-3 regular">
          Recently Sent
        </p>
        <div className="flex">
          {proposalCards &&
            proposalCards.map((p) => this.renderProposalCards(p))}
        </div>
        {showSendEmailModal && (
          <SendBatchProposalPDFsEmailModal
            hideModal={() => this.setState({ showSendEmailModal: false })}
            sendEmail={(recipients) =>
              this.onSendBatchProposalPDFEmail(recipients)
            }
          />
        )}
        {showBulkApprovalModal && (
          <OrderableConfirmationModal
            orderable="Order"
            orderableType="sales"
            params={selectedIds
              .map((id) => proposals.find((p) => p.id === id))
              .filter((proposal) => proposal)
              .map((proposal) => ({
                proposalId: proposal.id,
                date: Moment(proposal.clientSetUpTime),
              }))
              .sort((a, b) => (a.date.isBefore(b.date) ? -1 : 1))}
            coordinator={proposalsToOrders}
            uri={baseUri}
            errorLinks={[
              {
                message: '< Go back to Proposals Dashboard to Update Proposals',
                clickHandler: () => {
                  this.setState({ showBulkApprovalModal: false }, async () => {
                    await loadLandingProposals(Moment())
                  })
                },
              },
            ]}
            successLinks={[
              {
                message: 'Go to Client Sales History Tab to View Orders >',
                clickHandler: () => {
                  this.setState({ showBulkApprovalModal: false }, () => {
                    const proposal = proposals.find(
                      (p) => p.id === selectedIds[0],
                    )
                    if (!proposal) {
                      this.props.displayFailureMessage('Proposal not found')

                      return
                    }

                    const { baseUri } = this.props
                    window.open(
                      `${baseUri}/clients/?account_id=${proposal.accountId}&tab=salesHistory`,
                    )
                  })
                },
              },
            ]}
            onClose={() =>
              this.setState({ showBulkApprovalModal: false }, async () => {
                await loadLandingProposals(Moment())
                await this.loadProposals()
                this.setState({ selectedProposals: {} })
              })
            }
          />
        )}
        {showCalendar ? (
          <div className="flex mt-10 items-center justify-end">
            <div className="flex">
              <input
                className="border w-56 regular text-lg text-hungryGray pl-4 p-2 border-gray-400 rounded mr-6"
                type="text"
                placeholder="Search"
                value={search}
                onChange={this.onSearch}
              />
            </div>
            <div className="flex">
              <div className="flex items-center">
                <div className="flex items-center text-base regular mr-6">
                  <span className="w-5 h-5 rounded-full bg-yellow-400 mr-2" />
                  Not Approved
                </div>
                <div className="flex items-center text-base regular mr-4">
                  <span className="w-5 h-5 rounded-full bg-green-400 mr-2" />
                  Approved
                </div>
              </div>
              <div
                className={`${
                  !showCalendar && 'bg-indigo-400'
                } cursor-pointer ml-2 rounded flex items-center justify-center bg-indigo-200 w-10 h-10`}
                onClick={() =>
                  this.setState({ page: 1, showCalendar: false }, () => {
                    window.addEventListener('scroll', this.checkScroll)
                    this.loadProposals()
                  })
                }
              >
                <img className="w-6" src={list} />
              </div>
              <div
                className={`${
                  showCalendar && 'bg-indigo-400'
                } cursor-pointer ml-2 rounded flex items-center justify-center bg-indigo-200 w-10 h-10 mr-2`}
                onClick={() => this.setState({ showCalendar: true })}
              >
                <img className="w-6" src={calendarIcon} />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex mt-10 items-center justify-between">
            <div className="flex items-center">
              <p className="regular nowrap text-hungryGray text-sm xl:text-lg">
                All Proposals
              </p>
              <p className="regular ml-2 nowrap text-hungryGray text-sm xl:text-lg mr-2">
                &gt; Selected
              </p>
              <div className="flex justify-center items-center w-5 h-5 xl:w-8 xl:h-8 text-center rounded-full bg-indigo-400 text-white bold text-sm xl:text-lg mr-3">
                {selectedProposalsCount}
              </div>
              <select
                value={option}
                onChange={(e) => this.setState({ option: e.target.value })}
                className="border w-32 xl:w-48 regular text-sm xl:text-lg text-hungryGray p-2 bg-gray-100 rounded border-gray-400"
                data-testId="proposal-dash-action-dropdown"
              >
                <option default disabled value="">
                  Choose Action
                </option>
                {options.map((o) => (
                  <option key={o} value={o}>
                    {o}
                  </option>
                ))}
              </select>
              {this.renderNotPDFButtons(option)}
            </div>
            <div className="flex items-center">
              <input
                className="border w-32 xl:w-48 regular text-sm xl:text-lg text-hungryGray p-2 border-gray-400 rounded"
                type="text"
                placeholder="Search"
                value={search}
                onChange={this.onSearch}
              />
              <div className="relative border border-gray-400 flex ml-2">
                <DateRangePicker
                  startDate={this.state.startDate}
                  startDateId="start_date_id"
                  endDate={this.state.endDate}
                  endDateId="end_date_id"
                  onDatesChange={({ startDate, endDate }) =>
                    this.onDatesChange(startDate, endDate)
                  }
                  focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) =>
                    this.setState({ focusedInput })
                  }
                  displayFormat="MM/DD/YYYY"
                  enableOutsideDays={true}
                  isOutsideRange={() => false}
                />
                <p
                  className="regular mt-12 text-indigo-800 absolute cursor-pointer underline"
                  onClick={this.onClearDates}
                >
                  X Clear Dates
                </p>
              </div>
              <div className="flex">
                <div
                  className={`${
                    !showCalendar && 'bg-indigo-400'
                  } cursor-pointer ml-2 rounded flex items-center justify-center bg-indigo-200 w-10 h-10`}
                  onClick={() =>
                    this.setState({ page: 1, showCalendar: false })
                  }
                >
                  <img className="w-6" src={list} />
                </div>
                <div
                  className={`${
                    showCalendar && 'bg-indigo-400'
                  } cursor-pointer ml-2 rounded flex items-center justify-center bg-indigo-200 w-10 h-10 mr-2`}
                  onClick={() =>
                    this.setState({ showCalendar: true }, () => {
                      window.removeEventListener('scroll', this.checkScroll)
                      this.loadInitialProposalRange()
                    })
                  }
                >
                  <img className="w-6" src={calendarIcon} />
                </div>
              </div>
            </div>
          </div>
        )}
        {showCalendar ? (
          <div className="mt-3">
            <div className="flex justify-between">
              <div>{this.renderBackButton()}</div>
              {this.renderForwardButton()}
            </div>
            <div className="flex mb-20">
              {selectedMonth !== null ? (
                <Calendar
                  isLoading={isLoading}
                  isSmallCalendar={false}
                  selectMonth={(month) =>
                    this.setState({ selectedMonth: month })
                  }
                  month={selectedMonth}
                  year={startDate.year()}
                  proposals={proposals}
                  orders={orders}
                />
              ) : (
                this.generateSmallCalendars()
              )}
            </div>
          </div>
        ) : (
          <div>
            <table className="w-full mt-4">
              <tr className="border-b border-solid border-gray-500">
                <th className="pb-2 align-middle w-4">
                  <Checkbox
                    value="selectAll"
                    checked={selectAll}
                    onChange={this.onChangeCheckbox}
                    inputClass="proposal-checkbox"
                  />
                </th>
                <th
                  className="align-middle pb-2 regular nowrap text-hungryGray text-base cursor-pointer"
                  onClick={this.sortProposals('accountName')}
                >
                  Client/Number ▼
                </th>
                <th
                  className="align-middle regular nowrap text-hungryGray text-base text-center cursor-pointer"
                  onClick={this.sortProposals('clientSetUpTime')}
                >
                  Event Date ▼
                </th>
                <th
                  className="align-middle regular nowrap text-hungryGray text-base text-center cursor-pointer"
                  onClick={this.sortProposals('createdAt')}
                >
                  Proposal Create Date ▼
                </th>
                <th
                  className="align-middle regular nowrap text-hungryGray text-base text-center cursor-pointer"
                  onClick={this.sortProposals('clientSetUpTime')}
                >
                  Event Time ▼
                </th>
                <th
                  className="align-middle text-center regular nowrap text-hungryGray text-base cursor-pointer"
                  onClick={this.sortProposals('headCount')}
                >
                  Head Count ▼
                </th>
                <th
                  className="align-middle text-center regular nowrap text-hungryGray text-base cursor-pointer"
                  onClick={this.sortProposals('vendor')}
                >
                  Vendor / Menu Name ▼
                </th>
                <th />
                <th
                  className="align-middle text-right regular nowrap text-hungryGray text-base cursor-pointer"
                  onClick={this.sortProposals('total')}
                >
                  Amount / Status ▼
                </th>
                <th />
              </tr>
              {proposals && proposals.map((p) => this.renderProposal(p))}
            </table>
            <div className="mt-5 flex">
              <p className="regular text-base">{indexString}</p>
              {displayViewMore && (
                <button
                  className="border border-gray-400 border-solid px-5 py-2 rounded regular text-base hover:bg-gray-100 m-auto"
                  onClick={this.onViewMoreProposals}
                >
                  View More Proposals
                </button>
              )}
              {noProposalsToLoad && (
                <p className="border border-gray-400 border-solid px-5 py-2 rounded regular text-base hover:bg-gray-100 m-auto">
                  No more proposals
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}

ProposalDashboard.propTypes = {
  confirmationModal: PropTypes.any,
  loadingEmail: PropTypes.bool,
  calendarProposals: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  orders: PropTypes.arrayOf(PropTypes.object),
  proposals: PropTypes.arrayOf(PropTypes.object),
  proposalDashboard: PropTypes.arrayOf(PropTypes.object),
  proposalDashboardParams: PropTypes.object,
  userEmail: PropTypes.string,
  baseUri: PropTypes.string,

  clearProposals: PropTypes.func,
  deleteProposals: PropTypes.func,
  emailProposal: PropTypes.func,
  loadLandingProposals: PropTypes.func,
  loadProposals: PropTypes.func,
  loadDinerProfiles: PropTypes.func,
  newProposal: PropTypes.func,
  proposalAction: PropTypes.func,
  selectProposal: PropTypes.func,
  setLastLoadProposalDashboardParams: PropTypes.func,
  proposalsToOrders: PropTypes.func,
  displayFailureMessage: PropTypes.func,
}

export default ProposalDashboard
